// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-accueil-page-tsx": () => import("./../../../src/templates/AccueilPage.tsx" /* webpackChunkName: "component---src-templates-accueil-page-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/BlogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-cabinet-page-tsx": () => import("./../../../src/templates/CabinetPage.tsx" /* webpackChunkName: "component---src-templates-cabinet-page-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/DefaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-documents-page-tsx": () => import("./../../../src/templates/DocumentsPage.tsx" /* webpackChunkName: "component---src-templates-documents-page-tsx" */),
  "component---src-templates-equipe-page-tsx": () => import("./../../../src/templates/EquipePage.tsx" /* webpackChunkName: "component---src-templates-equipe-page-tsx" */),
  "component---src-templates-membre-page-tsx": () => import("./../../../src/templates/MembrePage.tsx" /* webpackChunkName: "component---src-templates-membre-page-tsx" */),
  "component---src-templates-single-post-tsx": () => import("./../../../src/templates/SinglePost.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */),
  "component---src-templates-soin-page-tsx": () => import("./../../../src/templates/SoinPage.tsx" /* webpackChunkName: "component---src-templates-soin-page-tsx" */),
  "component---src-templates-soins-page-tsx": () => import("./../../../src/templates/SoinsPage.tsx" /* webpackChunkName: "component---src-templates-soins-page-tsx" */),
  "component---src-templates-urgence-page-tsx": () => import("./../../../src/templates/UrgencePage.tsx" /* webpackChunkName: "component---src-templates-urgence-page-tsx" */)
}

